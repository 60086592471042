import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  List,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReleaseTopicLinks } from '../../../utils/interface/ReleaseInterface';
import { ReleaseTopics } from '../../../utils/interface/ReleaseTopicInterface';
import { ReleasesCard } from './ReleasesCard';
import DrawIcon from '@mui/icons-material/Draw';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { updateStatus } from '../../../services/ReleaseTopics';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { getReleaseTopicById, getReleaseTopicLinks } from '../../../services/ReleaseTopics';
import { AddValidationPlan } from './AddValidationPlan';
import { GROOMING, parseURI } from '../../../utils/parser/URIFormatter';
import { Conversations } from './Conversations';
import { EmptyResultPaper } from '../../shared-components/table/TableComponent';
import {
  DetailsHeadingText,
  DetailsInnerText,
  GridItemName,
  GridItemStatus,
  GridSecondaryItemName,
  HeaderGrid,
  HeaderLabel,
} from './styles/DetailsStyle';
import { BackdropComponent, BackdropSkeletonComponent } from '../../shared-components/backdrop/Backdrop';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { DaysLeft } from '../../shared-components/days-left/DaysLeft';
import { HeaderDetails } from './HeaderDetails';
import { UserPreview } from '../../shared-components/user-selector-dropdown/UserPreview';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export function Details(): React.ReactElement {
  const { projectId = '', topicId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [releaseTopic, setReleaseTopic] = useState<ReleaseTopics>();
  const [releaseTopicLink, setReleaseTopicLink] = useState<Array<ReleaseTopicLinks>>([]);
  const [value, setValue] = React.useState(0);
  const [status, setStatus] = React.useState('created');
  const [statusLoader, setStatusLoader] = React.useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getTopicByIdAPI = async () => {
    if (topicId) {
      const resultTopicDetails = await getReleaseTopicById(projectId, topicId);
      setOpen(true);
      setReleaseTopic(resultTopicDetails.data);
      setStatus(resultTopicDetails.data.status);
      setOpen(false);
    }
  };
  const getTopicLinksAPI = async () => {
    if (topicId) {
      const resultTopicDetails = await getReleaseTopicLinks(projectId, topicId);
      setReleaseTopicLink(resultTopicDetails.data.result);
    }
  };
  useEffect(() => {
    setOpen(true);
    getTopicByIdAPI();
    getTopicLinksAPI();
    setOpen(false);
  }, []);

  const updateTopicStatus = async () => {
    if (topicId) {
      setStatusLoader(true);
      const result = await updateStatus(projectId, topicId, {
        status: status,
      });
      console.log('Completed ', result);
      setStatusLoader(false);
    }
  };

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <HeaderGrid>
        {!open ? <HeaderDetails releaseTopic={releaseTopic} open={open} /> : <BackdropSkeletonComponent visible={true} />}
      </HeaderGrid>

      <Button variant="outlined" sx={{ mt: 2 }} href={parseURI(GROOMING, projectId, topicId)}>
        <DrawIcon sx={{ padding: '1px' }} fontSize="medium" /> Groom Topic
      </Button>

      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Release Commitment" {...a11yProps(0)} />
            <Tab label="Details" {...a11yProps(1)} />
            <Tab label="Conversations" {...a11yProps(2)} />
            <Tab label="Validation Plan" {...a11yProps(3)} />
            {/* <Tab label="attachments" {...a11yProps(4)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {releaseTopicLink && releaseTopicLink.length > 0 ? (
              releaseTopicLink.map((value) => (
                <ReleasesCard
                  releaseTopicPlannedReleaseDate={releaseTopic?.plannedReleaseDate}
                  statusCheck={releaseTopic?.status !== 'completed'}
                  release={value.result !== undefined ? value.result : []}
                ></ReleasesCard>
              ))
            ) : (
              <EmptyResultPaper />
            )}
          </List>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Card variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ m: 2, BorderBottom: '#1A2027' }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="body1" sx={DetailsHeadingText} component="div">
                      Description
                    </Typography>
                  </Stack>
                  <Typography color="text.secondary" variant="body1" sx={DetailsInnerText}>
                    {releaseTopic?.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ m: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="body1" sx={DetailsHeadingText} component="div">
                      Planned ReleaseDate
                    </Typography>
                  </Stack>
                  <Typography color="text.secondary" variant="body2" sx={DetailsInnerText}>
                    {ParseToDate(releaseTopic?.plannedReleaseDate)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ m: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="body1" sx={DetailsHeadingText} component="div">
                      Priority
                    </Typography>
                  </Stack>
                  <Typography color="text.secondary" sx={DetailsInnerText} variant="body2">
                    {releaseTopic?.priority}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ m: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="body1" sx={DetailsHeadingText} component="div">
                      Reference
                    </Typography>
                  </Stack>
                  <Typography color="text.secondary" sx={DetailsInnerText} variant="body2">
                    {releaseTopic?.reference}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ m: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="body1" sx={DetailsHeadingText} component="div">
                      Topic Source
                    </Typography>
                  </Stack>
                  <Typography color="text.secondary" sx={DetailsInnerText} variant="body2">
                    {releaseTopic && releaseTopic.topicSource ? (
                      <UserPreview ownerUser={releaseTopic.topicSource}></UserPreview>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ m: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="body1" sx={DetailsHeadingText} component="div">
                      Topic Owners
                    </Typography>
                  </Stack>
                  <Typography color="text.secondary" sx={DetailsInnerText} variant="body2">
                    {releaseTopic && releaseTopic.topicOwners ? (
                      <UserPreview ownerUser={releaseTopic.topicOwners}></UserPreview>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Conversations />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <AddValidationPlan releaseTopicId={topicId}></AddValidationPlan>
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={4}>
          Files
        </CustomTabPanel> */}
      </Box>
    </Container>
  );
}
